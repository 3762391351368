* {
  box-sizing: border-box;
  color: white;
}

body {
  margin: 0;
  background-color: #333;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

a {
  color: lightskyblue;
  text-decoration: none;
}

label > input,
select {
  padding: 5px;
  margin: 5px;
  width: 200px;
}

select,
option {
  color: black;
}

.header,
.search-box {
  width: 50%;
  padding: 5px;
  margin: 5px auto;
  border-radius: 8%;
  background-color: rgb(45, 45, 45);
}

.button {
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
}

.results,
.other-locations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.place-container {
  background-color: rgb(45, 45, 45);
  border-radius: 7%;
  padding: 15px;
  margin: 10px;
}

.place-logo > img {
  height: 125px;
}

.place-logo:hover {
  cursor: pointer;
  transform: scale(1.04);
  transition: transform 250ms ease-in-out;
}

.place-info:hover {
  cursor: pointer;
}

.place-details {
  width: 60%;
  margin: 5px auto;
  padding: 10px;
}

.place-details-logo > img {
  height: 100px;
}

.embed-map > iframe {
  width: 500px;
  height: 350px;
  border: 0;
}

@media screen and (max-width: 600px) {
  .header,
  .search-box {
    width: 90%;
  }

  .place-container {
    width: 45%;
    padding: 5px;
    margin: 5px;
  }

  .place-logo > img {
    height: 75px;
  }

  .place-info > h3,
  h4,
  h5,
  h6 {
    font-size: 0.85rem;
  }

  .place-details {
    width: 100%;
  }

  .embed-map > iframe {
    width: 300px;
    height: 200px;
  }
}
